<template>
    <div class="WmaCostPriceCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            style="text-align: right"
            :model="form"
            :rules="rules"
            ref="createForm"
            size="small"
        >
            <el-card style="margin-top: 8px">
                <el-row style="margin-top: 16px">
                    <ef-dept-type-and-dept-select @selectDept="setDept" @selectDeptType="setDeptType" />
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" style="width: 250px" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card style="margin-top: 8px; text-align: left">
                <el-button type="primary" :disabled="disableQueryAndAddButton" @click="queryAndAdd" size="small">
                    查询并添加
                </el-button>
                <el-button
                    type="primary"
                    :disabled="disableShowPickUpGoodsDialog"
                    @click="showPickUpGoods"
                    size="small"
                >
                    选择商品基本信息
                </el-button>
                <el-button type="primary" :disabled="disableSaveButton" @click="handleSave" size="small">
                    保存
                </el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="440"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品编码" width="250">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.goodsCode'"
                                :rules="rules.goodsCode"
                            >
                                {{ scope.row.goodsCode }}
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" width="250">
                        <template slot-scope="scope">
                            {{ goodsByFilterCode(scope.row.goodsCode).sku.name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="当前库存" width="150">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.stock'" :rules="rules.stock">
                                <el-input v-model="scope.row.stock" disabled />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="旧的移动加权成本单价(元)" width="200">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.oldStockCostPrice'"
                                :rules="rules.oldStockCostPrice"
                            >
                                {{ scope.row.oldStockCostPrice }}
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="新的移动加权成本单价(元)" width="200">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.newStockCostPrice'"
                                :rules="rules.newStockCostPrice"
                            >
                                <ef-price-input
                                    v-model="scope.row.newStockCostPrice"
                                    @change="computeWmaCostDiff(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="差异总计(元)" width="200">
                        <template slot-scope="scope">
                            {{ scope.row.wmaCostDiff }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pick-up-goods ref="pickupGoods" @queryGoods="setSelectedGoods" />
    </div>
</template>

<script>
import PickUpGoods from 'components/PickUpGoods';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import { countGreaterThanZeroValidateRule, countValidateRule } from 'js/validate/ValidateStockBizRule';
import { pricesValidateRule } from 'js/validate/ValidateCommonRule';
import Util from 'js/Util';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import EfPriceInput from 'components/EfPriceInput';

export default {
    name: 'WmaCostPriceCreate',
    components: { PickUpGoods, EfDeptTypeAndDeptSelect, EfPriceInput },
    data() {
        return {
            search: '',
            //表单数据，表格中要展示的非表单数据，从其他暂存的地方获取
            form: {
                deptCode: '',
                deptName: '',
                detailParams: [],
            },
            detailParamPropertyArr: [
                'goodsCode',
                'stock',
                new PropertyConverter('wmaCostPrice', 'oldStockCostPrice'),
                'newStockCostPrice',
                'wmaCostDiff',
            ],
            //goodsCode对应的商品的映射
            goodsDetailMap: new Map(),
            rules: {
                deptCode: [{ required: true, message: '请选择机构', trigger: 'change' }],
                goodsCode: [{ required: true, message: '请选择商品', trigger: 'change' }],
                stock: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                    countGreaterThanZeroValidateRule(),
                ],
                oldStockCostPrice: [{ required: true, message: '旧的移动加权成本单价必填', trigger: 'change' }],
                newStockCostPrice: pricesValidateRule(),
            },
        };
    },
    watch: {
        'form.deptCode': function (newDeptCode, oldDeptCode) {
            //清空商品列表数据
            this.form.detailParams = [];
        },
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return Util.isEmpty(this.form.deptCode) || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.deptCode);
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.deptCode) ||
                Util.isEmpty(this.form.detailParams) ||
                this.form.detailParams.length === 0
            );
        },
    },
    methods: {
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                await this.$efApi.wmaCostPriceChangeApi.create(this.form.deptCode, this.form.detailParams);
                this.goBackAndReload();
            });
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ deptCode: this.form.deptCode, search: this.search });
            this.setSelectedGoods(rst.data);
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show({ deptCode: this.form.deptCode }, this.form.deptName);
        },
        setSelectedGoods(selectedGoods) {
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                this.form.detailParams,
                this.detailParamPropertyArr
            );
        },
        goodsByFilterCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByFilterCode(goodsCode).sku;
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.deptType = deptType;
        },
        computeWmaCostDiff(row) {
            const stock = row.stock;
            const oldStockCostPrice = row.oldStockCostPrice;
            const newStockCostPrice = row.newStockCostPrice;
            if (!Util.isEmpty(stock) && !Util.isEmpty(oldStockCostPrice) && !Util.isEmpty(newStockCostPrice)) {
                row.wmaCostDiff = Util.multiply(Util.subtract(newStockCostPrice, oldStockCostPrice), stock);
            } else {
                row.wmaCostDiff = null;
            }
        },
    },
};
</script>
